* {
    margin: 0;
    padding: 0;
}

.select {
    font-family: "Twemoji Country Flags", "Helvetica", "Comic Sans", serif;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #3772ff;
    border: none;
    font-size: 16px;
    color: #fff;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    /*border-radius: 10px; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
}

.select:hover {
    color: #f2f2f2;
    background-color: #2F323A;
}

select{
    background-color: transparent;
    font-family: "Twemoji Country Flags", "Helvetica", "Comic Sans", serif;
    font-size: 16px;
    border: none;
    color: white;
    cursor: pointer;
}


option{
    background-color: #3772ff;
    font-family: "Twemoji Country Flags", "Helvetica", "Comic Sans", serif;
    border: none;
    text-transform: uppercase;
    color: black;
}


#language2{
    display: none;
}

body {
    font-family: "Twemoji Country Flags", "Helvetica", "Comic Sans", serif;
    color: white;
    height: 100vh;
    min-height: max-content;
}

.container {
    width: 100%;
    height: 100%;
    /*CSS smooth scroll */
    overflow-y: scroll;
    -ms-overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    -webkit-scroll-snap-type: y mandatory;
    -ms-scroll-snap-type: mandatory;
}

.navbar {
    display: flex;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 6%;
    background: transparent;
}

.navbar img {
    margin: 5px;
}

.nav-links {
    display: flex;
    list-style: none;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: right;
}

.nav-links li {
    margin-right: 1em;
}

.nav-links li a {
    padding: .25em;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size:16px;
    font-weight: 600;
    line-height: 45px;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 0;
    margin: 0 1em 2em;
}

.nav-links li a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transform: scaleX(0);
    transition: all 0.5s;
    transform-origin: left
}

.nav-links li a:hover:before, ul li a:hover:after {
    transform: scaleX(1);
}

.nav-links li a:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transform: scaleX(0);
    transition: all 0.5s;
    transform-origin: right
}

.burger {
    display: none;
    background: transparent;
}

.burger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px;
    transition: all 0.3s ease;
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
    position: relative;
}

section h1 {
    font-size: 4rem;
}

section p {
    font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
    body {
        overflow-x: hidden;
    }

    #language{
        display: none;
    }

    #language2{
        position: absolute;
        margin-left: 5px;
        margin-top: 5px;
        display: unset;
        font-weight: 600;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    #language2 option {
        font-weight: 600;
    }

    .navbar{
        background: rgba(0,0,0,0.8);
        backdrop-filter: saturate(180%) blur(10px);
    }
    .navbar img {
        margin-left: auto;
        margin-right: auto;
    }
    .nav-links {
        position: absolute;
        right: 0;
        background: rgba(0,0,0,0.8);
        backdrop-filter: saturate(180%) blur(10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
    }

    .nav-links li {
        opacity: 0;
    }

    .burger {
        display: block;
        cursor: pointer;
        position: fixed;
        right: 0;
        height: 6%;
        border-style: none;
        padding: 5px;
    }

}

.nav-active {
    transform: translateX(0%);
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    opacity: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}

/*test*/

.select-container {
    position: relative;
    display: inline-block;
    margin: 20px;
}

.select:focus {
    outline: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.select:after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
}

.select option {
    font-size: 16px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    /*border-radius: 10px; */
    transition: all 0.3s ease-in-out;
    background: #2F323A;
}