section#experience{
    background: #DF2935;
}

#CV{
    bottom: 2%;
    position: absolute;
    font-size: xx-large;
}
#CV a{
    color: #3772ff;
}

@media screen and (max-width: 767px) {
    #CV{
        font-size: x-large;
    }
}

@media (max-width: 375px) {

    .vertical-timeline {
        padding: unset;
    }

    .vertical-timeline-element:first-child {
        margin-top: 6%;
    }
    .vertical-timeline-element {
        position: relative;
        margin: 1em 0;
    }

    .vertical-timeline-element>div {
        min-height: 1px;
        padding-bottom: 3px;
    }

    #CV{
        font-size: larger;
    }
}

@media (max-height: 790px) {
    .vertical-timeline-element-content {
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0.5em;
    }
}

@media (max-height: 735px) {
    .vertical-timeline-element:first-child {
        margin-top: 2%;
    }
    .vertical-timeline-element {
        margin: 0.5em 0;
    }
}

@media (max-height: 700px) {
    .vertical-timeline-element-content p {
        margin: 0.7em 0 0;
        line-height: 1.2;
    }
}