section#about{
    background: #3772ff;
    flex-direction: row;
    justify-content: space-around;
}

#whoAleks{
    font-size: xxx-large;
}

#playlist {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: #2B2B2CFF;
    margin-top: 1%;
    margin-bottom: 5%;
    font-weight: 600;
    letter-spacing: 1px;
    /*border-radius: 10px; */
}

#track{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: #2F323A;
}

#trackOrder{
    padding-left: 5px;
    padding-top: 10px;
}
#wrapps{
  width: fit-content;
}

#trackImage{
    height: 48px;
    width: 48px;
    padding-left: 5px;
    padding-top: 6px;
}

#trackDiv{
    flex-direction: column;
}

#trackDiv a{
    color: white;
    text-decoration: none;
}

#trackDiv a:hover{
    color: #E15554;
}

#infoz a {
    text-decoration: none;
}

#infoz a:hover {
    color: #E15554;
}

#trackName{
    padding-top: 6px;
    padding-left: 6px;
    font-size: larger;
    text-align: left;
}

#trackArtists{
    font-size: small;
    padding-top: 6px;
    padding-left: 6px;
    text-align: left;
}

.fakeEmoji{
    height: 24px;
    width: 24px;
    vertical-align: middle;
}

#info{
    flex-direction: column;
    align-content: space-around;
    height: inherit;
    display: flex;
    margin-left: 2%;
    text-align: center;
}

#infoz{
    margin-top: auto;
    margin-bottom: auto;
    font-size: x-large;
}

#infoz div{
    margin-top: 3%;
}

#infoz a{
    color: white;
}

#acoBundle{
    flex-direction: column;
    text-align-last: end;
    display: flex;
    justify-content: center;
    margin-right: 2%;
}

#aco{
    height: 600px;
    margin: auto;
}
.rhap_container{
    background-color: #2B2B2CFF;
    outline: none;
    width: auto;
    box-shadow: none;
    padding: unset;
    margin-left: auto;
}

.rhap_play-pause-button{
    font-size: 30px;
    width: 30px;
    height: 30px;
    padding-top: 2px;
}

.rhap_container {
    background: #2F323A;
}

@media screen and (max-width: 768px) {
    section#about{
        flex-direction: column;
        justify-content: space-around;
        background-blend-mode: darken;
        background: #3772ff url("../../src/images/aleksander.webp") no-repeat;
        background-size: 180px;
        background-position: center;
    }
    #acoBundle{
        display: none;
    }
    #playlist {
        flex-direction: column;
        margin: 2% 2% 4%;
    }
    #aco{
        height: 200px;
    }

    #info{
        text-align: center;
        margin-top: 10%;
        margin-right: auto;
        margin-left: auto;
    }

    #info h3{
        text-align: unset;
    }

    #whoAleks{
        font-size: xx-large;
    }

    #infoz{
        margin-top: 10%;
        font-size: larger;
        padding: 3px;
    }

    #wrapps{
        width: unset;
    }
}