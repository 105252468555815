section#start {
    background: #DF2935;
}

#hello img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25rem;
}

.wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
}

@keyframes bounce {
    0%, 20%, 60%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

#hello img:hover{
    animation: bounce 1s;
}

#crypto img {
    background: #2f323a;
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 100px;
    width: 100px;
    margin: 20px;
    background-clip: content-box;
    /*border-radius: 8px; */
}

#crypto img:hover {
    background: #3772ff;
    background-clip: content-box;
    transform: scale(1.2);
}

#linkedin img {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 20px;
}

#linkedin img:hover {
    transform: scale(1.2);
    background-clip: content-box;
}

@media screen and (max-width: 768px) {
    #hello h1{
        font-size: xx-large;
        padding-bottom: 30px;
        text-align: center;
    }

    #hello img {
        width: 20rem;
    }

    #crypto img {
        height: 50px;
        width: 50px;
    }

    #crypto img:hover {
        transform: scale(1.2);
    }

    #linkedin img {
        height: 50px;
        width: 50px;
    }
}